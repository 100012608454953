<template>
  <div>
    <div class="flexRow justify-content-end">
      <el-button type="primary" @click="openBox(1)">添加</el-button>
    </div>
    <div class="flexRow align-item-center flexWrap" v-if="nodeList.length">
      <div class="listCell flexRow align-item-center justify-content-between" v-for="(item,index) in nodeList" :key="index">
        <span>{{item.name}}</span>
        <div>
          <el-button circle type="info" icon="el-icon-edit" @click="openBox(2,index)"></el-button>
          <el-button circle type="danger" icon="el-icon-delete" @click="delItem(item.id)"></el-button>
        </div>
      </div>
    </div>
    <!--编辑、新建-->
    <el-dialog :visible.sync="showBox" :close-on-click-modal="false" width="30%">
      <el-form>
        <el-form-item label="节点名称">
          <el-input placeholder="请输入节点名称" v-model="nodeName" clearable></el-input>
        </el-form-item>
      </el-form>
      <div class="marginTop flexRow justify-content-center">
        <el-button type="primary" @click="subMsg">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "addNode",
    data(){
      return{
        nodeName:"",
        nodeList:[],
        showBox:false,
      }
    },
    created(){
      this.getList();
    },
    methods:{
      //获取节点
      async getList(){
        let res = await this.mainFun('AdminGroup/getList',{list_rows:50});
        this.nodeList = res.data.data;
      },
      //打开弹窗
      openBox(index,tabIndex){
        if(index == 1){
          this.eid = "";
          this.nodeName = "";
        }else{
          this.eid = this.nodeList[tabIndex].id;
          this.nodeName = this.nodeList[tabIndex].name;
        }
        this.showBox = true;
      },
      //添加或编辑节点
      async subMsg(){
        if(this.nodeName == ""){
          this.$message.warning("请填写节点名称");
          return;
        }
        let params = {
          name:this.nodeName
        };
        let url = "AdminGroup/";
        if(this.eid){
          params.id = this.eid;
          url += 'edit'
        }else{
          url += 'add'
        }
        let res = await this.mainFun(url,params);
        if(res.data.code == 1){
          this.getList();
          this.$message.success(res.data.message);
          this.showBox = false;
        }else{
          this.$message.error(res.data.message)
        }
      },
      //删除节点
      delItem(id){
        this.$confirm('提示','是否确认删除此节点',{
          confirmButtonText:"确定",
          cancelButtonText:"取消",
          type:"warning"
        }).then(async ()=>{
          let res = await this.mainFun('AdminGroup/del',{id});
          if(res.data.code == 1){
            this.getList();
            this.$message.success(res.data.message);
          }else{
            this.$message.error(res.data.message)
          }
        })
      },
    }
  }
</script>

<style scoped lang="less">
.listCell{
  padding:15px;
  width:240px;
  box-sizing: border-box;
  border:2px dashed #329DFF;
  border-radius: 5px;
  font-size:16px;
  color:#329DFF;
  margin:0 15px 15px 0;
}
</style>
